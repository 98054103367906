<template>
    <div :class="[
            {'o365-body-cell': !col.disableDefaultClass},
            selectionClass,
            col.cellClass,
            userClass,
            {'d-none': isActiveEditCell, 'o365-body-cell-editable': col.editable }
        ]"
        :style="[
            {'width': col.width + 'px'},
            {'left': col.left + 'px'},
            {'user-select': col.editable ? 'none': ''},
            userStyle
        ]"
        :data-o365-colindex="col.order"
        :title="title"
        tabindex="-1" :o365-field="col.colId">
        <slot>
            <template v-if="col.slots.default">
                <component :is="col.slots.default" :row="row.item" :column="col" :rowIndex="row.index"></component>
            </template>
            <template v-else-if="col.cellRenderer">
                <component :is="col.cellRenderer" :row="row.item" :column="col"></component>
            </template>
            <template v-else>
                {{row.item[col.field]}}
            </template>
        </slot>
    </div>
</template>

<script lang="ts">
import type BaseColumn from 'o365.controls.Grid.BaseColumn.ts';

export interface IBaseCellProps {
    col: BaseColumn;
    row: {item: any, index: number};
    selectionClass?: string;
    isActiveEditCell?: boolean;
};
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<IBaseCellProps>();

/** User provided class function from column definition */
const userClass = computed(() => {
    if (typeof props.col.classFn === 'function') {
        return props.col.classFn(props.row.item);
    } else {
        return null;
    }
});

/** User provided styles from column definition */
const userStyle = computed(() => {
    if (typeof props.col.cellStyle === 'function') {
        return props.col.cellStyle(props.row.item);
    } else {
        return props.col.cellStyle;
    }
});

/** Cell title from column definition */
const title = computed(() => {
    if (typeof props.col.cellTitle === 'function') {
        return props.col.cellTitle(props.row.item);
    } else {
        return props.col.cellTitle;
    }
});
</script>